
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'

import VueClipboard from 'vue-clipboard2'

const app = createApp(App)
installElementPlus(app)
app.use(store)
.use(router)
.use(VueClipboard)
.mount('#app')

import * as socketApi from './utils/socket'

app.config.globalProperties.socketApi = socketApi;
