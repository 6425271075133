import {
	createRouter,
	createWebHistory
} from "vue-router";

// import Home from "../views/Home.vue";

const routes = [

{
	path: '/',
	component: () => import('../views/login/index.vue')
},
// {
// 	path: "/",
// 	name: "Home",
// 	component: Home,
// 	children: [
// 		{
// 			path: '/home',
// 			name: 'home',
// 			meta: {
// 				title: '用户首页'
// 			},
// 			component: () => import('../views/circle/home.vue')
// 		},
// 		{
// 			path: '/dynamic',
// 			name: 'dynamic',
// 			meta: {
// 				title: '动态列表'
// 			},
// 			component: () => import('../views/circle/dynamic.vue')
// 		},
// 		{
// 			path: '/topic',
// 			name: 'topic',
// 			meta: {
// 				title: '圈子列表'
// 			},
// 			component: () => import('../views/circle/topic.vue')
// 		},
// 		{
// 			path: '/report',
// 			name: 'report',
// 			meta: {
// 				title: '举报列表'
// 			},
// 			component: () => import('../views/circle/report.vue')
// 		},
// 		{
// 			path: '/config',
// 			name: 'config',
// 			meta: {
// 				title: '配置列表'
// 			},
// 			component: () => import('../views/circle/config.vue')
// 		},
// 		{
// 				path: '/manghe',
// 				name: 'manghe',
// 				meta: {
// 					title: '盲盒配置'
// 				},
// 				component: () => import('../views/circle/manghe.vue')
// 		},
// 		{
// 			path: '/twitter_function',
// 			name: 'twitter_function',
// 			meta: {
// 				title: '功能列表'
// 			},
// 			component: () => import('../views/twitter_function.vue')
// 		},
// 		{
// 			path: '/twitter_user_functions',
// 			name: 'twitter_user_functions',
// 			meta: {
// 				title: '用户功能列表'
// 			},
// 			component: () => import('../views/twitter_user_functions.vue')
// 		},
// 		{
// 			path: '/tuiteindex',
// 			name: 'tuiteindex',
// 			meta: {
// 				title: '用户列表'
// 			},
// 			component: () => import('../views/TuiTeHome.vue')
// 		},
// 		{
// 			path: '/tuiterecord',
// 			name: 'tuiterecord',
// 			meta: {
// 				title: '充值记录'
// 			},
// 			component: () => import('../views/twitter_record.vue')
// 		}, 
// 		{
// 			path: '/subindex',
// 			name: 'subindex',
// 			meta: {
// 				title: '下级列表'
// 			},
// 			component: () => import('../views/Subindex.vue')
// 		}, {
// 			path: '/singlesub',
// 			name: 'singlesub',
// 			meta: {
// 				title: '下级收益详情'
// 			},
// 			component: () => import('../views/SingleSub.vue')
// 		},
// 		{
// 			path: '/withdraw',
// 			name: 'withdraw',
// 			meta: {
// 				title: '提现详情'
// 			},
// 			component: () => import('../views/WithDraw.vue')
// 		},
// 		{
// 			path: '/distribution',
// 			name: 'distribution',
// 			meta: {
// 				title: '分销配置'
// 			},
// 			component: () => import('../views/Distribution.vue')
// 		},
// 		{
// 			path: "/table",
// 			name: "basetable",
// 			meta: {
// 				title: '表格'
// 			},
// 			component: () => import("../views/BaseTable.vue")
// 		}, {
// 			path: "/charts",
// 			name: "basecharts",
// 			meta: {
// 				title: '图表'
// 			},
// 			component: () => import("../views/BaseCharts.vue")
// 		}, {
// 			path: "/form",
// 			name: "baseform",
// 			meta: {
// 				title: '表单'
// 			},
// 			component: () => import("../views/BaseForm.vue")
// 		}, {
// 			path: "/tabs",
// 			name: "tabs",
// 			meta: {
// 				title: 'tab标签'
// 			},
// 			component: () => import("../views/Tabs.vue")
// 		}, {
// 			path: "/donate",
// 			name: "donate",
// 			meta: {
// 				title: '鼓励作者'
// 			},
// 			component: () => import("../views/Donate.vue")
// 		}, {
// 			path: "/permission",
// 			name: "permission",
// 			meta: {
// 				title: '权限管理',
// 				permission: true
// 			},
// 			component: () => import("../views/Permission.vue")
// 		}, {
// 			path: "/i18n",
// 			name: "i18n",
// 			meta: {
// 				title: '国际化语言'
// 			},
// 			component: () => import("../views/I18n.vue")
// 		}, {
// 			path: "/upload",
// 			name: "upload",
// 			meta: {
// 				title: '上传插件'
// 			},
// 			component: () => import("../views/Upload.vue")
// 		}, {
// 			path: "/icon",
// 			name: "icon",
// 			meta: {
// 				title: '自定义图标'
// 			},
// 			component: () => import("../views/Icon.vue")
// 		}, {
// 			path: '/404',
// 			name: '404',
// 			meta: {
// 				title: '找不到页面'
// 			},
// 			component: () => import('../views/404.vue')
// 		}, {
// 			path: '/403',
// 			name: '403',
// 			meta: {
// 				title: '没有权限'
// 			},
// 			component: () => import('../views/403.vue')
// 		}
// 	]
// },
// {
// 	path: '/home',
// 	name: 'home',
// 	meta: {
// 		title: '首页'
// 	},
// 	component: () => import('../views/logo.vue')
// },
// {
// 	path: '/user',
// 	name: 'user',
// 	meta: {
// 		title: '用户'
// 	},
// 	component: () => import('../views/twitter/user.vue')
// },
// {
// 	path: '/private',
// 	name: 'private',
// 	meta: {
// 		title: '隐私政策'
// 	},
// 	component: () => import('../views/twitter/private.vue')
// },
// {
// 	path: '/market',
// 	name: 'market',
// 	meta: {
// 		title: '账号交易'
// 	},
// 	component: () => import('../views/twitter/market.vue')
// },
// {
// 	path: '/order',
// 	name: 'order',
// 	meta: {
// 		title: '下单'
// 	},
// 	component: () => import('../views/twitter/order.vue')
// },
// {
// 	path: '/chat',
// 	name: 'chat',
// 	meta: {
// 		title: '会话'
// 	},
// 	component: () => import('../views/twitter/chat.vue')
// },
// {
// 	path: '/detection',
// 	name: 'detection',
// 	meta: {
// 		title: '查验'
// 	},
// 	component: () => import('../views/twitter/detection.vue')
// },
// {
// 	path: '/account_detail',
// 	name: 'account_detail',
// 	meta: {
// 		title: '账号详情'
// 	},
// 	component: () => import('../views/twitter/account_detail.vue')
// },
// {
// 	path: '/add',
// 	name: 'add',
// 	meta: {
// 		title: '创建账号'
// 	},
// 	component: () => import('../views/twitter/add.vue')
// },
// {
// 	path: '/order1',
// 	name: 'order1',
// 	meta: {
// 		title: '会话'
// 	},
// 	component: () => import('../views/circle/order.vue')
// },
// {
// 	path: '/charts',
// 	name: 'charts',
// 	meta: {
// 		title: '图标'
// 	},
// 	component: () => import('../views/BaseCharts.vue')
// },
// {
// 	path: "/login",
// 	name: "Login",
// 	meta: {
// 		title: '登录'
// 	},
// 	component: () => import("../views/Login.vue")
// }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	
	// 配置标题
	document.title = "服务至上";
	
	next();
	
	// 未登录的配置
	/*
	const token = localStorage.getItem('token');
	if(token){
		next();
	}else{
		if(to.path !== '/home'){
			if(to.path == "/private"){
				next();
			}else{
				next('/home');
			}
		}else{
			next();
		}
	}
	*/
	
	// const role = localStorage.getItem('ms_username');
	// if (!role && to.path !== '/login') {
	// 	// next('/login');
	// } else if (to.meta.permission) {
	// 	// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
	// 	role === 'admin' ?
	// 		next() :
	// 		next('/403');
	// } else {
	// 	next();
	// }
});

export default router;
